import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../Components/CustomButton';
import InputField from '../../../Components/Input';
import { FORM_STEPS, nameRegex } from '../../../Utils/constantData';
import { fetchLocation, validateZipCode } from '../../../Utils/helperFns';
import { notify } from '../../../Utils/toastify';
import { ContactInfoSchema } from '../../../Utils/yupvalidations';
import {
  saveUserData,
  stepRegisterCall,
  updateStep,
} from '../../../redux/auth.slice';
import { RootState, useAppSelector } from '../../../redux/store';
import { ApiStatus } from '../../../types';
import { useTranslation } from 'react-i18next';
import AutoCompleteLocation from '../../../Components/AutoCompleteLocation';

interface Props {
  changeStep: (step: number) => void;
  setCanMove: (flag: boolean) => void;
}

export default function ContactInfo({ changeStep, setCanMove }: Props) {
  const { userData, apiStatus } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const [load, setLoad] = useState<boolean>(false);
  const [addressFieldsEditable, setAddressFieldsEditable] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    clearErrors,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(ContactInfoSchema),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (userData) {
      const { residentialAddress } = userData;
      if (residentialAddress && residentialAddress.addressLineOne) {
        const fieldNames = Object.keys(ContactInfoSchema.fields);
        fieldNames.forEach((name: any) => {
          if (name === 'country') {
            setValue(name, residentialAddress[name]);
          } else if (name === 'addressLineTwo') {
            setValue(
              name,
              residentialAddress[name] === null
                ? undefined
                : residentialAddress[name],
            );
          } else if (name === 'dob') {
            setValue(name, dayjs(residentialAddress[name]));
          } else {
            setValue(name, residentialAddress[name]);
          }
        });
      }
      setValue('firstName', userData.firstName);
      setValue('lastName', userData.lastName);
      setValue('middleName', userData.middleName);
      clearErrors();
    }
    trigger();
  }, [userData]);

  useEffect(() => {
    if (isDirty) {
      setCanMove(false);
    }
  }, [isDirty]);

  const onSubmit = (data: any) => {
    const payload = data;
    payload['type'] = FORM_STEPS.CONTACT_INFO;
    if (data['country'] == null) {
      delete payload['country'];
    } else {
      payload['country'] = data['country'];
    }
    if (payload['middleName'] == null) delete payload['middleName'];
    if (!payload['addressLineTwo']) {
      delete payload['addressLineTwo'];
    }
    stepRegisterCall(payload, (data) => {
      notify('Contact Information saved successfully. ', 'success');
      dispatch(updateStep({ step: FORM_STEPS.LEGAL_DOC }));
      dispatch(
        saveUserData({
          value: data,
        }),
      );
      changeStep(2);
    });
  };

  const zipCodeWatch = watch('zipCode');

  const fetchAddressInfo = async (code: string): Promise<void> => {
    setLoad(true);
    try {
      const data = await fetchLocation(code);
      const addressComponents = data.results[0]?.address_components || [];
      const city = addressComponents.find(
        (component: any) =>
          component.types.includes('locality') ||
          component.types.includes('political'),
      )?.long_name;
      const state = addressComponents.find((component: any) =>
        component.types.includes('administrative_area_level_1'),
      )?.long_name;
      const country = addressComponents.find((component: any) =>
        component.types.includes('country'),
      )?.long_name;
      if (city) {
        setValue('city', city);
        clearErrors('city');
      } else {
        setValue('city', '');
      }
      if (state) {
        setValue('state', state);
        clearErrors('state');
      } else {
        setValue('state', '');
      }

      if (country) {
        setValue('country', country);
        clearErrors('country');
        setAddressFieldsEditable(true);
      } else {
        setValue('country', '');
        setAddressFieldsEditable(false);
      }
      trigger();
      setLoad(false);
    } catch (error) {
      setLoad(true);
    }
  };

  const handleOnkeyBlur = (event: any) => {
    if (zipCodeWatch && validateZipCode(zipCodeWatch)) {
      fetchAddressInfo(zipCodeWatch);
    } else {
      setValue('country', '');
      setValue('state', '');
      setValue('city', '');
    }
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'vendor_registration.contact_info',
  });

  return (
    <div className="businessInfo_step contactInfo">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <div className="formLabel mtb_30">
            <h3>{t('primary_contact_information')}</h3>
          </div>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <InputField
                requiredField
                id="First_Name"
                label={t('first_name')}
                name="firstName"
                placeholder={t('first_name_placeholder')}
                error={!!errors['firstName']}
                helperText={
                  !!errors['firstName'] ? errors['firstName'].message : ``
                }
                control={control}
                charLimit={25}
                customRegex={nameRegex}
                hintText={`${t('first_name_hint')}`}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                id="MiddleName"
                label={t('middle_name')}
                name="middleName"
                clearErrors={clearErrors}
                placeholder={t('middle_name_placeholder')}
                error={!!errors['middleName']}
                helperText={
                  !!errors['middleName'] ? errors['middleName'].message : ``
                }
                control={control}
                charLimit={25}
                customRegex={nameRegex}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                requiredField
                id="LastName"
                label={t('last_name')}
                name="lastName"
                placeholder={t('last_name_placeholder')}
                error={!!errors['lastName']}
                helperText={
                  !!errors['lastName'] ? errors['lastName'].message : ``
                }
                control={control}
                charLimit={25}
                customRegex={nameRegex}
              />
            </Grid>
          </Grid>
          {/* <div className="formLabel">
            <h3>Country of citizenship</h3>
          </div> */}
          <Grid container spacing={2}>
            {/* <Grid item md={4} xs={12}>
              <AutocompleteSelect
                requiredField
                id="Country"
                labelText="Country"
                placeHolderText="Select country"
                name="country"
                control={control}
                error={!!errors['country']}
                setValue={setValue}
                helperText={
                  !!errors['country'] ? `${errors['country'].message}` : ''
                }
                isCountryField={true}
                options={countryList}
                defaultValue=""
                disabled={false}
              />
            </Grid> */}
            {/* <Grid item md={4} xs={12}>
              <DateInput
                maxDate={`${maxDate}`}
                defaultValue={`${today}`}
                requiredField
                label="Date of Birth"
                minDate={new Date('1900-01-01').toISOString()}
                name="dob"
                formProps={{ control }}
                error={!!errors['dob']}
                helperText={
                  !!errors['dob'] && (
                    <p className="helper-error">{errors['dob'].message}</p>
                  )
                }
              />
            </Grid> */}
          </Grid>

          <div className="formLabel">
            <div className="h_35"></div>
            <h3>{t('residential_address')}</h3>
          </div>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              {/* <InputField
                id="address_line_1"
                label={t('address_line_1')}
                name="addressLineOne"
                placeholder={t('enter_address')}
                error={!!errors['addressLineOne']}
                helperText={
                  !!errors['addressLineOne']
                    ? errors['addressLineOne'].message
                    : ``
                }
                control={control}
              /> */}
              <AutoCompleteLocation
                id="address_line_2"
                labelText={t('address_line_1')}
                name="addressLineOne"
                placeHolderText={t('enter_address')}
                error={!!errors['addressLineOne']}
                helperText={
                  !!errors['addressLineOne']
                    ? errors['addressLineOne'].message
                    : ``
                }
                requiredField
                control={control}
                setValue={setValue}
                limit={100}
                changeCallBack={(result: any) => {
                  setValue('addressLineOne', result.description);
                }}
                defaultValue={watch('addressLineOne')}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                id="address_line_2"
                label={t('address_line_2')}
                name="addressLineTwo"
                placeholder=""
                error={!!errors['addressLineTwo']}
                helperText={
                  !!errors['addressLineTwo']
                    ? errors['addressLineTwo'].message
                    : ``
                }
                control={control}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                id="zip_postal_code"
                label={t('zip_postal_code')}
                name="zipCode"
                placeholder={t('enter_zip_postal_code')}
                error={!!errors['zipCode']}
                helperText={
                  !!errors['zipCode'] ? errors['zipCode'].message : ``
                }
                control={control}
                onBlur={handleOnkeyBlur}
                charLimit={9}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <InputField
                id="city_town"
                label={t('city_town')}
                name="city"
                placeholder={t('please_enter_city')}
                error={!!errors['city']}
                helperText={!!errors['city'] ? errors['city'].message : ``}
                control={control}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                id="state"
                label={t('state')}
                name="state"
                placeholder={t('please_enter_state')}
                control={control}
                error={!!errors['state']}
                helperText={
                  !!errors['state'] ? `${errors['state'].message}` : ''
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                id="Country"
                label={t('country')}
                placeholder={t('please_enter_postal_country')}
                name="country"
                control={control}
                error={!!errors['country']}
                // setValue={setValue}
                helperText={
                  !!errors['country'] ? `${errors['country'].message}` : ''
                }
                // isCountryField={true}
                // options={[]}
                // defaultValue=''
                // disabled={true}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="buttonContainer">
          <CustomButton
            size="large"
            variant="contained"
            text={t('continue')}
            showIcon={false}
            width="100%"
            type="submit"
            id="login"
            loading={load || apiStatus === ApiStatus.loading}
            disabled={!isValid || load}
          />
        </Box>
      </form>
    </div>
  );
}
