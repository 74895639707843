import FormControl from '@mui/material/FormControl';
import React, { useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import InputCheckbox from './CheckBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Images from '../Utils/images';

interface Props {
  id: string;
  labelText?: string;
  placeHolderText?: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  defaultValue?: string | any;
  disabled?: boolean;
  value?: any;
  onChange?: any;
  options: any;
  control?: null | any;
  setValue?: any;
  isError?: boolean;
  isCountryField?: boolean;
  isCountry?: boolean;
  multiple?: boolean;
  isCheckbox?: boolean;
  requiredField?: boolean;
  labelKey?: string;
  limit?: number;
  clearIcon?: boolean;
  disableClearable?: boolean;
  onInputChange?: any;
  handleBlur?: any;
  onOpen?: any;
  freeSolo?: boolean;
  isArrow?: boolean;
  onKeyDown?: any;
}
export default function AutocompleteSelect(props: Props) {
  const {
    id,
    labelText,
    placeHolderText,
    name = '',
    error,
    helperText,
    defaultValue,
    disabled,
    options,
    control,
    setValue,
    isCountryField = false,
    isCountry = false,
    multiple,
    isCheckbox,
    requiredField,
    onChange,
    value,
    labelKey,
    limit = 1,
    clearIcon = false,
    disableClearable = false,
    onInputChange,
    handleBlur,
    onOpen,
    freeSolo = false,
    isArrow = false,
  } = props;

  return (
    <FormControl
      fullWidth
      className="customAutocomplete"
      error={error ? true : false}
    >
      {labelText && (
        <FormLabel className="formLabel">
          {labelText} {requiredField ? <sup>*</sup> : null}{' '}
        </FormLabel>
      )}

      {control ? (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : null}
          render={({ field }) => {
            return (
              <Autocomplete
                limitTags={limit}
                clearIcon={clearIcon}
                {...field}
                // freeSolo={freeSolo}
                disableClearable={disableClearable}
                disablePortal
                id="combo-box-demo"
                size="small"
                openOnFocus={true}
                onOpen={() => {
                  onOpen && onOpen();
                }}
                placeholder={placeHolderText}
                disabled={disabled}
                popupIcon={!isArrow ? <KeyboardArrowDownIcon /> : null}
                onInputChange={onInputChange ? onInputChange : undefined}
                getOptionLabel={
                  isCountry
                    ? (option) => (option ? option.name || option : '')
                    : labelKey
                    ? (option) => (option ? option[labelKey] : '')
                    : undefined
                }
                defaultValue={defaultValue}
                disableCloseOnSelect={multiple}
                isOptionEqualToValue={
                  labelKey
                    ? (option, value) => {
                        return option[labelKey] === value[labelKey];
                      }
                    : undefined
                }
                renderOption={
                  isCountryField
                    ? (props, option) => (
                        <Box
                          key={option.id}
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            key={option.id}
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt="Country Flag Logo"
                          />
                          {option.name} ({option.code})
                        </Box>
                      )
                    : isCheckbox
                    ? (props, options, { selected }) => {
                        return (
                          <li {...props} key={id}>
                            {isCheckbox ? (
                              <InputCheckbox
                                id={`check_${name}`}
                                name={`check_${name}`}
                                checked={selected}
                              />
                            ) : (
                              ''
                            )}
                            {labelKey ? options[labelKey] : options}
                          </li>
                        );
                      }
                    : labelKey
                    ? (props, option) => {
                        return (
                          <li {...props} key={id}>
                            {option[labelKey]}
                          </li>
                        );
                      }
                    : undefined
                }
                options={options}
                onChange={
                  onChange
                    ? onChange
                    : (event, newValue: any, reason) => {
                        setValue(name, newValue);
                      }
                }
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    placeholder={placeHolderText}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      autoComplete: 'off',
                      // onKeyDown: handleKeyDown,
                      'aria-autocomplete': 'none',
                    }}
                    autoComplete="off"
                    aria-autocomplete="none"
                    error={error ? true : false}
                  />
                )}
                multiple={multiple}
                ChipProps={{
                  deleteIcon: <img src={Images.CHIP_DELETE} alt="" />,
                }}
              />
            );
          }}
        />
      ) : (
        <Autocomplete
          clearIcon={clearIcon}
          limitTags={limit}
          disablePortal
          id="combo-box-demo"
          size="small"
          options={options}
          placeholder={placeHolderText}
          onChange={onChange}
          sx={{ width: '100%' }}
          value={value}
          disabled={disabled}
          openOnFocus={true}
          disableCloseOnSelect={multiple}
          popupIcon={<KeyboardArrowDownIcon />}
          multiple={multiple}
          isOptionEqualToValue={
            labelKey
              ? (option, value) => {
                  return option[labelKey] === value?.[labelKey];
                }
              : undefined
          }
          renderOption={
            isCheckbox
              ? (props, options, { selected }) => {
                  return (
                    <li {...props} key={id}>
                      {isCheckbox ? (
                        <InputCheckbox
                          id={`check_${name}`}
                          name={`check_${name}`}
                          checked={selected}
                        />
                      ) : (
                        ''
                      )}
                      {labelKey ? options[labelKey] : options}
                    </li>
                  );
                }
              : labelKey
              ? (props, option) => {
                  return (
                    <li {...props} key={id} id={option['_id'] || 'li'}>
                      {option[labelKey]}
                    </li>
                  );
                }
              : undefined
          }
          getOptionLabel={
            isCountry
              ? (option) => (option ? option.name || option : '')
              : labelKey
              ? (option) => (option ? option[labelKey] : '')
              : undefined
          }
          renderInput={(params) => (
            <TextField
              placeholder={placeHolderText}
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                autoComplete: 'off',
                // onKeyDown: onKeyDown,

                'aria-autocomplete': 'none',
              }}
              autoComplete="off"
              aria-autocomplete="none"
              error={error ? true : false}
            />
          )}
          ChipProps={{
            deleteIcon: <img src={Images.CHIP_DELETE} alt="" />,
          }}
        />
      )}

      <FormHelperText className={error ? 'Mui-error' : ''}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}
