import store, { RootState } from './store';
import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import { apiSuccessCode, getApiCall, putApiCall } from '../api/methods';
import { CabState } from '../types';
import { startLoading, stopLoading } from './loaders.slice';
import {
  AssignDriverLoader,
  BookingListLoader,
  DriverListLoader,
  MarkAsCompleteLoader,
  VehicleListLoader,
} from '../types/loaderTypes';
import { useSocket } from '../Pages/Chats/SocketContext';
import { deepClone } from '../Utils/helperFns';

const initialState: CabState = {
  bookingList: null,
  searchKeywords: '',
  vehicleList: null,
  driverList: null,
  assignModalData: null,
  bookingSuccessModal: false,
};

const cabSlice = createSlice({
  name: 'cab',
  initialState,
  reducers: {
    setBookingList: (state, action) => {
      const { payload }: any = action;
      state.bookingList = payload;
    },
    setVehicleList: (state, action) => {
      const { payload }: any = action;
      state.vehicleList = payload;
    },
    setDriverList: (state, action) => {
      const { payload }: any = action;
      state.driverList = payload;
    },
    setSearchKeywords: (state, action) => {
      const { payload }: any = action;
      state.searchKeywords = payload;
    },
    setAssignModalData: (state, action) => {
      const { payload }: any = action;
      state.assignModalData = payload;
    },
    setBookingSuccessModalState: (state, action) => {
      const { payload }: any = action;
      state.bookingSuccessModal = payload;
    },
  },
});

export const {
  setBookingList,
  setSearchKeywords,
  setVehicleList,
  setAssignModalData,
  setDriverList,
  setBookingSuccessModalState,
} = cabSlice.actions;

export default cabSlice.reducer;

const handleError = (e: any) => {
  const { dispatch } = store;
  if (e.data && e.data.message) {
    notify(e.data.message, 'error');
  } else {
    notify(null, 'error');
  }
};

export const getAndSetBookingList = (
  query: string = '?page=1&limit=10',
): any => {
  const { dispatch, getState } = store;
  const {
    cab: { searchKeywords },
  } = getState();
  const searchQuery = searchKeywords
    ? `&searchKey=${encodeURIComponent(searchKeywords)}`
    : '';

  const params = `${query}${searchQuery}`;
  dispatch(startLoading(BookingListLoader));

  getApiCall(
    `${endPoints.cab.list}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setBookingList(data.data));
      }
      dispatch(stopLoading(BookingListLoader));
    },
    (e: any) => {
      handleError(e);
      dispatch(stopLoading(BookingListLoader));
    },
  );
};

export const getAndSetVehicleList = (
  query: string = '?page=1&limit=100',
): any => {
  const { dispatch, getState } = store;
  dispatch(startLoading(VehicleListLoader));
  getApiCall(
    `${endPoints.cab.vehicleList}${query}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setVehicleList(data.data));
      }
      dispatch(stopLoading(VehicleListLoader));
    },
    (e: any) => {
      handleError(e);
      dispatch(stopLoading(VehicleListLoader));
    },
  );
};

export const getAndSetDriverList = (
  query: string = '?page=1&limit=100',
): any => {
  const { dispatch } = store;
  dispatch(startLoading(DriverListLoader));
  getApiCall(
    `${endPoints.cab.driverList}${query}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setDriverList(data.data));
      }
      dispatch(stopLoading(DriverListLoader));
    },
    (e: any) => {
      handleError(e);
      dispatch(stopLoading(DriverListLoader));
    },
  );
};

export const assignDriver = (payload: any, cb: (flag: boolean) => void) => {
  const { dispatch } = store;
  dispatch(startLoading(AssignDriverLoader));
  putApiCall(
    endPoints.cab.assignAndReassign,
    payload,
    (s: any) => {
      const { data } = s;
      if (data.statusCode === apiSuccessCode.success) {
        cb(true);
      } else {
        cb(false);
      }
      dispatch(stopLoading(AssignDriverLoader));
    },
    (e: any) => {
      handleError(e);
      dispatch(stopLoading(AssignDriverLoader));
    },
  );
};

export const markAsCompleted = (payload: any, cb: (flag: boolean) => void) => {
  const { dispatch } = store;
  dispatch(startLoading(MarkAsCompleteLoader));
  putApiCall(
    endPoints.cab.markAsComplete,
    payload,
    (s: any) => {
      const { data } = s;
      console.log(data);

      if (data.statusCode === apiSuccessCode.success) {
        cb(true);
      } else {
        cb(false);
      }
      dispatch(stopLoading(MarkAsCompleteLoader));
    },
    (e: any) => {
      handleError(e);
      dispatch(stopLoading(MarkAsCompleteLoader));
    },
  );
};
