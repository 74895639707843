export const LocationListLoader = 'LocationListLoader';

export const AddNotesLoader = 'AddNotesLoader';

export const ShiftsLoader = 'ShiftsLoader';

export const ShiftUpdateLoader = 'ShiftUpdateLoader';

export const ShiftApproveRejectLoader = 'ShiftApproveRejectLoader';

export const AllLocationItemsLoader = 'AllLocationItemsLoader';

export const DeleteOrderItemsLoader = 'DeleteOrderItemsLoader';

export const locationUpdateLoader = 'locationUpdateLoader';

export const setListLoader = 'setListLoader';

export const updateItemsSetLoader = 'updateItemsSetLoader';

export const OrderRentItemsLoader = 'OrderRentItemsLoader';

export const OrderBuyItemsLoader = 'OrderBuyItemsLoader';

export const BookingListLoader = 'BookingListLoader';

export const VehicleListLoader = 'VehicleListLoader';

export const DriverListLoader = 'DriverListLoader';

export const AssignDriverLoader = 'AssignDriverLoader';

export const MarkAsCompleteLoader = 'MarkAsCompleteLoader';
