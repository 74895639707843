import React, { ErrorInfo, ReactNode } from 'react';
import NoData from './Nodata';
import { Box, Typography } from '@mui/material';
import Header from '../Containers/Header';
import { ROUTES } from '../helpers/contants';
import NoInternet from './NoInternet';
class State {
  hasError: boolean = false;
  error: any = null;
  info: { componentStack: string } = {
    componentStack: '',
  };
  name: string = '';
  crashPath: string = '';
  isNetworkError: boolean = false;
  isChunkLoadError: boolean = false;
}
interface Props {
  children?: ReactNode;
}
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
      info: { componentStack: '' },
      crashPath: '',
      name: '',
      isNetworkError: false,
      isChunkLoadError: false,
    };
  }

  public static getDerivedStateFromError(error: any): Partial<State> {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(componentError: Error, errorInfo: ErrorInfo) {
    const time = new Date().getTime() * 1000;
    const isNetworkError = this.isNetworkError(componentError);
    const isChunkLoadError = this.isChunkLoadError(componentError);
    const newError = {
      error: componentError.message,
      name: componentError.name,
      cause: componentError.cause,
      info: errorInfo,
      hasError: true,
      crashPath: window.location.pathname,
      isNetworkError,
      isChunkLoadError,
    };

    // window.localStorage.setItem(time.toString(), JSON.stringify(newError));
    this.setState({
      ...newError,
    });
  }
  handleRetry = () => {
    window.location.reload();
  };
  private isChunkLoadError(error: Error): boolean {
    return (
      /Loading chunk /.test(error.message) ||
      error.message.includes('ERR_INTERNET_DISCONNECTED')
    );
  }
  componentDidUpdate(_: any, prevState: Readonly<State>): void {
    if (
      prevState.hasError &&
      prevState.crashPath &&
      window.location.pathname !== prevState.crashPath
    ) {
      this.setState({ ...new State(), hasError: false });
    }
  }

  private isNetworkError(error: Error): boolean {
    // Customize the condition based on how your application detects network errors
    return (
      error.message === 'Network Error' ||
      error.message.includes('timeout') ||
      error.message.includes('NetworkError')
    );
  }

  render() {
    const { hasError, info, isNetworkError, isChunkLoadError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="main">
          <Header />
          <Box
            sx={{
              display: 'grid',
              placeItems: 'center',
              height: '80vh',
            }}
          >
            {isNetworkError || isChunkLoadError ? (
              <NoInternet
                heading="No Internet Connection"
                title="The features in this area require Internet connectivity. Please connect your computer to the Internet and try again."
                buttonText="Refresh"
                handleClick={() => this.handleRetry()}
                showButton={true}
              />
            ) : (
              <NoData
                heading="Something went wrong"
                buttonText="Home"
                link={ROUTES.DASHBOARD}
                node={
                  <>
                    <Typography fontWeight={600}>
                      <b>Error Name :</b> {this.state.error}
                    </Typography>
                    <Typography>
                      <pre>
                        <b>Error Stack :</b>
                        {info.componentStack}
                      </pre>
                    </Typography>
                  </>
                }
              />
            )}
          </Box>
        </div>
      );
    }

    return <>{children}</>;
  }
}
export default ErrorBoundary;
