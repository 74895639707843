import { RootState, useAppSelector } from '../../redux/store';
import CheckIcon from '@mui/icons-material/Check';
import { StepIconProps } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WarningDialog from '../../Components/WarningDialog';
import { activeStyle, baseStyle, completedStyle, FORM_STEPS } from '../../Utils/constantData';
import { ROUTES } from '../../helpers/contants';
import { getAndSetData } from '../../redux/common.slice';
import '../../styles/auth.scss';
import Business from './Components/Business';
import ContactInfo from './Components/ContactInfo';
import LegalDocuments from './Components/LegalDocuments';
import { useTranslation } from 'react-i18next';
function BusinessInfo() {
  const { userData } = useAppSelector((state: RootState) => state.auth);
  const { countryList, departmentList } = useAppSelector(
    (state: RootState) => state.common,
  );

  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const [canMove, setCanMove] = useState<boolean>(true);
  const [showWarning, setWarning] = useState<boolean>(false);
  const [stepToMove, setStepToMove] = useState<number | null>(null);
  const [blockToNavigate, setBlockToNavigate] = useState<boolean>(false);

  useEffect(() => {
    if (userData) {
      if (userData.formNextStep === FORM_STEPS.CONTACT_INFO) {
        setActiveStep(1);
      } else if (userData.formNextStep === FORM_STEPS.LEGAL_DOC) {
        setActiveStep(2);
      } else if (userData.formNextStep === FORM_STEPS.BILLING_DETAILS) {
        navigate(ROUTES.DASHBOARD);
      }
      if (countryList.length === 0) {
        getAndSetData('country');
      }
      if (departmentList && departmentList.length === 0) {
        getAndSetData('department');
      }
    }
  }, [userData]);

  const { t } = useTranslation('translation', {
    keyPrefix: 'vendor_registration',
  });
  const steps = [
    {
      label: `${t('title_1')}`,
      component: <Business changeStep={setActiveStep} />,
    },
    {
      label: `${t('title_2')}`,
      component: (
        <ContactInfo setCanMove={setCanMove} changeStep={setActiveStep} />
      ),
    },
    {
      label: `${t('title_3')}`,
      component: (
        <LegalDocuments
          changeStep={setActiveStep}
          setCanMove={setCanMove}
          setBlockToNavigate={setBlockToNavigate}
        />
      ),
    },

    // 'Billing Details',
  ];

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
   ...baseStyle,
   ...(ownerState.active && activeStyle),
   ...(ownerState.completed && completedStyle),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? (
          <CheckIcon />
        ) : active ? (
          <div className="step_active"></div>
        ) : (
          // <CircleOutlinedIcon />
          <></>
        )}
      </ColorlibStepIconRoot>
    );
  }

  const switchForms = (index: number) => {
    if (userData) {
      const { formNextStep } = userData;

      if (
        formNextStep === FORM_STEPS.BUSINESS_INFO ||
        formNextStep === FORM_STEPS.BILLING_DETAILS
      ) {
        return;
      } else if (formNextStep === FORM_STEPS.CONTACT_INFO && index <= 1) {
        canMove ? setActiveStep(index) : setWarning(true);
      } else if (formNextStep === FORM_STEPS.LEGAL_DOC && index <= 2) {
        canMove ? setActiveStep(index) : setWarning(true);
      }

      setStepToMove(index);
    }
  };

  return (
    <div className="businessInfo">
      <div className="infoHeader">
        <h1>{t('heading')}</h1>
        <div className="stepper">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel
                    {...labelProps}
                    StepIconComponent={ColorlibStepIcon}
                    onClick={() => {
                      activeStep === 3 || blockToNavigate
                        ? undefined
                        : switchForms(index);
                    }}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </div>
      <div className="stepperContainer">{steps[activeStep].component}</div>

      <WarningDialog
        open={showWarning}
        handleClose={() => {
          setWarning(false);
        }}
        onClick={() => {
          setWarning(false);
          if (stepToMove !== null) setActiveStep(stepToMove);
          setStepToMove(null);
          setCanMove(true);
        }}
        cancelBtnText={t('stay')}
        confirmBtnText={t('leave')}
        title={t('unsaved_changes')}
        description={t('leaving_this_page_message')}
        type="warning"
        showIcon={false}
      />
    </div>
  );
}

export default BusinessInfo;
