import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../helpers/contants';
import React, { useEffect, useRef } from 'react';
import { RootState, useAppSelector } from '../redux/store';
import { ManagerNav, USER_ROLES } from '../Utils/constantData';
import { routeTypes } from '../types';
import { notify } from '../Utils/toastify';
import { useTranslation } from 'react-i18next';
import { removeAfterSecondSlash } from '../Utils/helperFns';

interface Props {
  children: React.ReactNode;
  route: routeTypes;
}

const PrivateRoute: React.FC<any> = ({ children, route }: Props | any) => {
  const location = useLocation();
  const { status = undefined, userData } = useAppSelector((state: RootState) =>
    state.auth ? state.auth : { status: undefined, userData: {} },
  );
  // Use useRef to store the previous location
  const previousLocation = useRef(location.pathname);
  const { t } = useTranslation('translation', {
    keyPrefix: 'Common',
  });
  useEffect(() => {
    // Update the previous location with the current location after each render
    if (location.pathname !== previousLocation.current) {
      previousLocation.current = location.pathname;
    }
  }, [location]);

  if (status && userData.role && userData.role === USER_ROLES.BRANCH_MANAGER) {
    return ManagerNav.includes(route.name) ? (
      children
    ) : (
      <Navigate to={ROUTES.DASHBOARD} />
    );
  } else if (
    status &&
    userData.role &&
    userData.role === USER_ROLES.CO_VENDOR &&
    !userData.isReelVendor
  ) {
    const vendorModules = Object.keys(userData.permissions);
    if (route.path !== ROUTES.DASHBOARD && route.path !== ROUTES.PROFILE) {
      if (vendorModules.includes(route.name)) {
        return children;
      } else {
        notify(t('msgs.err.accessMsg'), 'error');
        if (route.path.includes(':id')) {
          const crntPath = removeAfterSecondSlash(route.path);
          const prevPath = removeAfterSecondSlash(previousLocation.current);
          return crntPath !== prevPath ? (
            <Navigate to={previousLocation.current} />
          ) : (
            <Navigate to={ROUTES.DASHBOARD} />
          );
        }
        return route.path !== previousLocation.current ? (
          <Navigate to={previousLocation.current} />
        ) : (
          <Navigate to={ROUTES.DASHBOARD} />
        );
      }
    }
  }

  return status ? children : <Navigate to={ROUTES.LOGIN} />;
};

export default PrivateRoute;
