//User Services
const uS = `user-service/reel/api/v1/`;
//Vendor Services
const vS = `vendor-service/api/v1/`;
const riskManangerServices = '';
const endPoints = {
  sendotp: `${uS}vendor/send-otp`,
  resetpassword: `${uS}vendor/reset-password`,
  register: `${uS}vendor/signup`,
  login: `${uS}vendor/login`,
  logout: `${uS}vendor/logout`,
  verify: `${uS}vendor/verify-otp`,
  businessRegister: `${uS}vendor/business/registration`,
  common: {
    deparmentList: `${vS}dept-list`,
    countryList: `${uS}user/country-list`,
    categories: `${vS}categories`,
  },
  staticData: {
    content: `${vS}static-content/`,
  },
  chat: {
    getQuote: `${vS}quotation/`,
    createChat: `${vS}user/chat`,
    employeeList: `${vS}user/chat/vendors`,
  },
  product: {
    create: `${vS}products`,
    list: `${vS}products`,
    edit: `${vS}products/`,
    delete: `${vS}products/`,
    update: `${vS}products/`,
    statusUpdate: `${vS}products/`,
    templateList: `${vS}product/templates`,
    sequenceList: `${vS}products/sequence`,
    package: {
      create: `${vS}packages`,
      list: `${vS}packages`,
      edit: `${vS}packages/`,
      delete: `${vS}packages/`,
      update: `${vS}packages/`,
      statusUpdate: `${vS}packages/`,
      templateList: `${vS}packages/templates`,
      sequenceList: `${vS}packages/sequence`,
    },
    productVariant: {
      createVariant: `${vS}variant-products`,
      updateVariant: (id: string) => `${vS}variant-products/${id}`,
      deleteProductVariant: (id: string) =>
        `${vS}products/product-variant/${id}`,
      // -/products/{productId}/variant/property/{id}
      deleteAlreadyVariant: (productId: string, id: string) =>
        `${vS}products/${productId}/variant/property/${id}`,
    },
  },
  order: {
    list: `${vS}order`,
    listAllItemOrder: `${vS}order/items-addresses`,
    cancleOrder: `${vS}order/cancel`,
    productItems: `${vS}order/items`,
    setList: `${vS}order/set`,
    updateSets: `${vS}order/set-location`,
    updateQuantity: `${vS}order/quantity`,
    updateRentQuantity: (id: string) => `${vS}order/${id}/qty`,
    updateStatus: `${vS}order/status`,
    viewWorksheet: (id: string) => `${vS}order/products/${id}/worksheet`,
    extend_service: (id: string) => `${vS}order/products/${id}/extend-service`,
    updateShift: (id: string) => `${vS}order/shifts/${id}`,
    delteShifts: `${vS}order/shifts`,
    locationUpdate: `${vS}order/shifts/location`,
    updateStatusShift: (id: string) => `${vS}order/shifts/${id}/accept-reject`,
    addProducts: (id: string) => `${vS}order/${id}/add-more`,
    updateAddress: `${vS}order/address`,
    rentApproveReject: `${vS}order/`,
    productDetails: `${vS}order/products`,
    verifyRentalDetails: `${vS}chat/service/validate-time`,
    createCart: `${vS}cart`,
    addItemServicesToOrder: `${vS}order/add-more-service`,
    updateCartOrDelete: `${vS}cart`,
    quotationCart: `${vS}quotation_product/cart`,
    quotationShifts: `${vS}quotation_cart/shifts`,
    quotationCartDetails: `${vS}quotation_cart/`,
    cartRentalDetailsUpdate: (id: string) => `${vS}cart/${id}/service-period`,
    getOrderStatus: (id: string) => `${vS}orders/${id}/status`,
    globalApproval: (id: string) => `${vS}order/${id}/global-approval`,
    draft: {
      CRUD: `${vS}order-draft`,
      addProducts: `${vS}vendor-cart/products`,
      createOrder: `${vS}vendor-order`,
      createCartId: `${vS}vendor-cart`,
    },
    locationList: (id: string) => `${vS}orders/${id}/locations`,
    notes: {
      addNote: `${vS}orders/notes`,
    },
  },
  company: {
    getOrPut: `${vS}companies`,
  },
  client: {
    getClientList: `${vS}vendor/client`,
    getSet: `${vS}vendor/client/set`,
    getClientInfo: (id: string) => `${vS}vendor/client/${id}`,
    getSetDetails: `${vS}vendor/client/set-location`,
    getSetOrders: `${vS}vendor/client/set-order`,
    getRateAndDiscount: (id: string) => `${vS}vendor/client/discount/${id}`,
    postRateAndDiscount: `${vS}vendor/client/discount`,
    updateRateAndDiscount: `${vS}vendor/client/discount`,
    postDocument: `${vS}vendor/client/document`,
    getDocuments: `${vS}vendor/client/document`,
    getAssociations: (id: string) => `${vS}vendor/client/associations/${id}`,
    removeDiscout: (id: string) => `${vS}vendor/client/discount/${id}`,
  },
  branch: {
    CRUD: `${vS}branches`,
    managerAddOrUpdate: `${vS}branches/managers`,
    zones: `${vS}branches/zones`,
    holidays: `${vS}holidays`,
  },
  profile: {
    getOrUpdate: `${vS}vendors/settings`,
    updatePass: `${uS}vendor/update-password`,
  },
  //Co-vendor name changed to employ
  coVendor: {
    permissionTable: `${vS}co-vendors/permissions`,
    vendorCRUD: `${vS}co-vendors`,
  },
  notification: {
    updateDeviceToken: `/notification-service/reel/api/v1/user/apns/token`,
    updateDeviceTokenFCM: `/notification-service/reel/api/v1/vendor/apns/token`,
    notifications: `/notification-service/reel/api/v1/vendor/notifications`,
  },
  riskmanagement: {
    risklist: 'risk-manager-service/reel/api/v1/vendor/securityManager',
    orderrequest: 'risk-manager-service/reel/api/v1/vendor/request',
    addriskManager:
      'risk-manager-service/reel/api/v1/vendor/securityManager/add',
    setAvailability:
      'risk-manager-service/reel/api/v1/vendor/securityManager/set-unavailability',
    userDetails: 'risk-manager-service/reel/api/v1/vendor/securityManager',
    updateRiskManager:
      'risk-manager-service/reel/api/v1/vendor/securityManager/edit/',
    deleteExperience:
      'risk-manager-service/reel/api/v1/vendor/securityManager/experience',
    blockUnblock:
      'risk-manager-service/reel/api/v1/vendor/securityManager/status/',
    entityListing:
      'risk-manager-service/reel/api/v1/vendor/securityManager/entity',
    countryCityListing:
      'risk-manager-service/reel/api/v1/securityManager/cities',
    countryList: 'risk-manager-service/reel/api/v1/securityManager/country',
    departmentList:
      'risk-manager-service/reel/api/v1/vendor/securityManager/department',
    studioList:
      '/risk-manager-service/reel/api/v1/vendor/securityManager/studio',
    productionList:
      '/risk-manager-service/reel/api/v1/vendor/securityManager/production',
    companyList:
      '/risk-manager-service/reel/api/v1/vendor/securityManager/company',
    university:
      '/risk-manager-service/reel/api/v1/vendor/securityManager/university',
    cancleOrder: '/risk-manager-service/reel/api/v1/vendor/request/status',
    degree: '/risk-manager-service/reel/api/v1/vendor/securityManager/degree',
    addCompanyManual:
      '/risk-manager-service/reel/api/v1/vendor/securityManager/company',
    addSchoolManual:
      '/risk-manager-service/reel/api/v1/vendor/securityManager/university',
  },
  group: {
    addGroup: `${vS}product/group`,
    getGroups: `${vS}product/groups`,
    editGroup: (id: string) => `${vS}product/groups/${id}`,
    getGroupDetails: (id: string) => `${vS}product/groups/${id}`,
    deleteItem: (query: string) => `${vS}product/groups/item${query}`,
  },
  cab: {
    list: `${vS}bookings`,
    vehicleList: `${vS}vehicles`,
    driverList: `${vS}drivers`,
    assignAndReassign: `${vS}booking/assign-reassign`,
    markAsComplete: `${vS}booking/mark-as-completed`,
  },
};

export default endPoints;
