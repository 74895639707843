import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import {
  apiErrorCode,
  apiSuccessCode,
  deleteApiCall,
  getApiCall,
  patchApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import {
  ApiStatus,
  Department,
  ProductState,
  RiskManagementState,
} from '../types';
import { setCommonDataLoader, updateActionApiStatus } from './common.slice';
import store, { RootState, useAppSelector } from './store';
import { logout } from './auth.slice';
import React from 'react';
import dayjs from 'dayjs';
import { get } from 'react-hook-form';

const initialState: RiskManagementState = {
  riskList: [],
  riskListData: [],
  ordersList: {},
  accountList: {},
  usersList: {},
  loading: false,
  error: false,
  filter: null,
  searchKeywords: '',
  userData: null,
  currentTab: 2,
  entitylist: [],
  countrylist: {},
  citylist: {},
  departmentList: {},
  studioList: {},
  productionList: {},
  companyList: {},
  universityList: {},
  pageNo: 1,
  editedItem: null,
  orderrequestlist: {},
  degreeList: {},
  cancleOrderModalDataRequest: null,
};

const riskMngslice = createSlice({
  name: 'riskmng',
  initialState,
  reducers: {
    setRiskmngList: (state, action) => {
      const { payload }: any = action;
      state.riskList = payload;
    },
    setRiskmngListData: (state, action) => {
      const { payload }: any = action;
      // If payload is empty or no results found, set riskListData to an empty array
      if (payload.data.length === 0) {
        state.riskListData = [];
      } else {
        // Replace previous data with the new search results (not appending)
        state.riskListData = [...state.riskListData, ...payload?.data];
      }
      // state.riskListData = [...state.riskListData , ...payload?.data];
    },
    setPageNo: (state, action) => {
      const { payload }: any = action;
      state.pageNo = payload;
    },
    setUsersList: (state, action) => {
      const { payload }: any = action;
      state.usersList = payload;
    },
    setAccountsList: (state, action) => {
      const { payload }: any = action;
      state.accountList = payload;
    },
    setSearchKeywords: (state, action) => {
      const { payload }: any = action;
      state.searchKeywords = payload;
    },
    setFilters: (state, action) => {
      const { payload }: any = action;

      state.filter = payload;
    },
    setUserDetails: (state, action) => {
      const { payload }: any = action;
      state.userData = payload;
    },
    setCurrentTab: (state, action) => {
      // Action to set the current tab
      state.currentTab = action.payload;
    },
    setEntityListing: (state, action) => {
      const { payload }: any = action;
      state.entitylist = payload;
    },
    setCountryCityList: (state, action) => {
      const { payload }: any = action;
      state.countrylist = payload;
    },
    setCountryList: (state, action) => {
      const { payload }: any = action;
      state.citylist = payload;
    },
    setDepartmentList: (state, action) => {
      const { payload }: any = action;
      state.departmentList = payload;
    },
    setStudioList: (state, action) => {
      const { payload }: any = action;
      state.studioList = payload;
    },
    setProductionList: (state, action) => {
      const { payload }: any = action;
      state.productionList = payload;
    },
    setCompanyList: (state, action) => {
      const { payload }: any = action;
      state.companyList = payload;
    },
    setUniversityList: (state, action) => {
      const { payload }: any = action;
      state.universityList = payload;
    },
    setOrderRequestList: (state, action) => {
      const { payload }: any = action;
      state.orderrequestlist = payload;
    },
    setCancelModalDataRequest: (
      state,
      action: PayloadAction<{
        isOrderCancle: boolean;
        orderDetails?: any;
      } | null>,
    ) => {
      const { payload } = action;
      state.cancleOrderModalDataRequest = payload;
    },
    setEditedItem: (state, action) => {
      const { payload }: any = action;
      state.editedItem = payload;
    },
    setDegreeList: (state, action) => {
      const { payload }: any = action;
      state.degreeList = payload;
    },
  },
});

export const {
  setRiskmngList,
  setFilters,
  setSearchKeywords,
  setUserDetails,
  setDepartmentList,
  setCurrentTab,
  setUsersList,
  setAccountsList,
  setEntityListing,
  setCountryCityList,
  setCountryList,
  setStudioList,
  setProductionList,
  setCompanyList,
  setDegreeList,
  setUniversityList,
  setPageNo,
  setEditedItem: setEditedItemStore,
  setOrderRequestList,
  setCancelModalDataRequest,
  setRiskmngListData,
} = riskMngslice.actions;

const handleError = (e: any) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.failed));

  if (e.data && e.data.message) {
    notify(e.data.message, 'error');
  }
  if (e.data && e.data.statusCode === apiErrorCode.unauthorized) {
    logout();
  }
};

export const getRiskManagementList = (
  query: string = '?pageNo=1&limit=10',
  type: string = '',
  setHasmore?: any,
): any => {
  const { getState } = store;
  const searchKeyword = getState().riskMngslice.searchKeywords;
  const searchQuery = searchKeyword
    ? `&searchKey=${encodeURIComponent(searchKeyword)}`
    : '';
  const filters = getState().riskMngslice.filter;

  let filterQuery = '';
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate());
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.status) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&status=${filters.status?.toUpperCase()}`;
      // })
    }
    if (filters.availableStatus) {
      // Map status to the required format
      const formattedStatus =
        filters.availableStatus.toLowerCase() === 'available'
          ? 'AVAILABLE'
          : filters.availableStatus.toLowerCase() === 'unavailable'
          ? 'UN_AVAILABLE'
          : filters.availableStatus.toUpperCase().replace(/\s+/g, '_');

      filterQuery += `&availableStatus=${formattedStatus}`;
    }
  }
  let listType = '';
  if (Boolean(type)) {
    listType += `&tabName=${type}`;
  }
  const params = `${query}${listType}${searchQuery}${filterQuery}`;
  const apiEndpoint = endPoints.riskmanagement.risklist;
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        if (type === 'USERS') {
          dispatch(setUsersList(data.data));
          dispatch(setPageNo(data.data.pageNo));
        } else {
          dispatch(setAccountsList(data.data));
        }
        if (setHasmore) {
          setHasmore(data?.data?.data.length < 10 ? false : true);
        }
        dispatch(setRiskmngList(data.data));
        dispatch(setRiskmngListData(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getOrderRequestListing = (
  query: string = '?pageNo=1&limit=10',
  type: string = '',
): any => {
  const { getState } = store;
  const searchKeyword = getState().riskMngslice.searchKeywords;
  const searchQuery = searchKeyword
    ? `&searchKey=${encodeURIComponent(searchKeyword)}`
    : '';
  const filters = getState().riskMngslice.filter;

  let filterQuery = '';
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate());
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.status) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&status=${filters.status?.toUpperCase()}`;
      // })
    }
    if (filters.availableStatus) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&availableStatus=${filters.availableStatus
        ?.toUpperCase()
        .replace(/\s+/g, '_')}`;
      // })
    }
  }
  let listType = '';
  if (Boolean(type)) {
    listType += `&tabName=${type}`;
  }
  const params = `${query}${listType}${searchQuery}${filterQuery}`;
  const apiEndpoint = endPoints.riskmanagement.orderrequest;
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        // if (type === 'USERS') {
        //   dispatch(setOrderRequestList(data.data));
        //   dispatch(setPageNo(data.data.pageNo));
        // } else {
        //   dispatch(setAccountsList(data.data));
        // }
        // dispatch(setRiskmngList(data.data));
        dispatch(setOrderRequestList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const cancleOrderRequest = (data: any, cb: (data: boolean) => void) => {
  const { dispatch, getState } = store;
  const {
    riskMngslice: { cancleOrderModalDataRequest, editedItem },
  }: RootState = getState();
  dispatch(setCommonDataLoader(true));
  const cloneData = { ...data };
  if (!cancleOrderModalDataRequest?.isOrderCancle && editedItem) {
    cloneData['orderId'] = editedItem.product._id;
  } else if (cancleOrderModalDataRequest) {
    const { orderDetails } = cancleOrderModalDataRequest;
    cloneData['vendorOrderId'] = orderDetails.vendorOrderId;
  }
  if (cloneData['cancelNotes'] && cloneData['cancelNotes'].trim() == '') {
    delete cloneData['cancelNotes'];
  }
  patchApiCall(
    endPoints.riskmanagement.cancleOrder,
    cloneData,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.success) {
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(true));
    },
  );
};
export const getOrderRequestListingforTab = (
  query: string = '?pageNo=1&limit=10',
  securityManagerId: any,
): any => {
  const { getState } = store;
  const searchKeyword = getState().riskMngslice.searchKeywords;
  const searchQuery = searchKeyword
    ? `&searchKey=${encodeURIComponent(searchKeyword)}`
    : '';
  const filters = getState().riskMngslice.filter;

  let filterQuery = '';
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate() + 2);
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.status) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&status=${filters.status?.toUpperCase()}`;
      // })
    }
    if (filters.availableStatus) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&availableStatus=${filters.availableStatus
        ?.toUpperCase()
        .replace(/\s+/g, '_')}`;
      // })
    }
  }
  let listType = '';

  const params = `${query}&securityManagerId=${securityManagerId}${searchQuery}${filterQuery}`;
  const apiEndpoint = endPoints.riskmanagement.orderrequest;
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        // if (type === 'USERS') {
        //   dispatch(setOrderRequestList(data.data));
        //   dispatch(setPageNo(data.data.pageNo));
        // } else {
        //   dispatch(setAccountsList(data.data));
        // }
        // dispatch(setRiskmngList(data.data));
        dispatch(setOrderRequestList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const addRiskManager = (data: any, cb: () => void) => {
  const { dispatch, getState } = store;

  postApiCall(
    endPoints.riskmanagement.addriskManager,
    data,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;

      if (statusCode && statusCode === apiSuccessCode.created) {
        notify(s.data.message, 'success');
        cb();
      } else if (statusCode && statusCode === apiErrorCode.validationError) {
        notify(s.data.message, 'error');
      }

      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const setAvailability = (data: any, page: any) => {
  const { dispatch, getState } = store;

  patchApiCall(
    endPoints.riskmanagement.setAvailability,
    data,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;

      if (statusCode && statusCode === apiSuccessCode.success) {
        notify(s.data.message, 'success');
        // cb();
        dispatch(setCommonDataLoader(true));
        getRiskManagementList(`?pageNo=${page}&limit=10`, 'USERS');
      } else if (statusCode && statusCode === apiErrorCode.validationError) {
        notify(s.data.message, 'error');
      }

      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getUserDetails = (id: string): any => {
  const { dispatch } = store;
  const apiEndpoint = endPoints.riskmanagement.userDetails;
  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}/${id}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCommonDataLoader(false));
        dispatch(setUserDetails(data.data));
      }
    },
    (e: any) => {
      dispatch(setCommonDataLoader(false));
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching branches list`, 'error');
      }
    },
  );
};
export const getDepartmentList = (): any => {
  const { dispatch } = store;
  const apiEndpoint = endPoints.riskmanagement.departmentList;
  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCommonDataLoader(false));
        dispatch(setDepartmentList(data.data));
      }
    },
    (e: any) => {
      dispatch(setCommonDataLoader(false));
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        // notify(`Error while fetching branches list`, 'error');
      }
    },
  );
};
export const updateRiskManager = (data: any, id: any, cb: any) => {
  const { dispatch, getState } = store;
  const apiendpoint = endPoints.riskmanagement.updateRiskManager;
  const params = id;
  putApiCall(
    `${apiendpoint}${params}`,
    data,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.created) {
        notify(s.data.message, 'success');
        cb();
      } else if (statusCode && statusCode === apiErrorCode.validationError) {
        notify(s.data.message, 'error');
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
    },
  );
};
export const deleteExperience = (payload: any, id?: any) => {
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));
  deleteApiCall(
    `${endPoints.riskmanagement.deleteExperience}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      dispatch(setCommonDataLoader(false));
      if (id) {
        getUserDetails(id);
      }
    },
    (e: any) => {
      // handleError(e)
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const blockUnblockUser = (
  data: any,
  id: any,
  callback: any,
  pageNo?: number,
) => {
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));

  const { status } = data;

  patchApiCall(
    `${endPoints.riskmanagement.blockUnblock}${id}`,
    data,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.success) {
        notify(message, 'success');
        getRiskManagementList(`?pageNo=${pageNo || 1}&limit=10`, 'USERS');
        callback();
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      // cb(false)
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getEntityListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.entityListing;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setEntityListing(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getCountryCityListing = (searchKey?: string) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint =
    endPoints.riskmanagement.countryCityListing +
    (searchKey ? '?limit=50&searchKey=' + searchKey : '');
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCountryCityList(data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getCountryListing = (id: any) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.countryList + '?cityId=' + id;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCountryList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getStudioListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.studioList;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setStudioList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getProductionListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.productionList;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setProductionList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getCompanyListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.companyList;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCompanyList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getDegreeListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.degree;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setDegreeList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getUniversityListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.university;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setUniversityList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const addCompanyManual = (
  values: any,
  cb?: any,
  handleClose?: any,
  reset?: any,
) => {
  const { dispatch, getState } = store;

  postApiCall(
    endPoints.riskmanagement.addCompanyManual,
    values,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        notify(s.data.message, 'success');
        // if(cb()) {
        cb({ ...values, _id: data._id });
        if (handleClose) {
          handleClose();
          reset();
        }
        // }
      } else if (statusCode && statusCode === apiErrorCode.validationError) {
        notify(s.data.message, 'error');
      }

      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const addSchoolManual = (
  values: any,
  cb?: any,
  handleClose?: any,
  reset?: any,
) => {
  const { dispatch, getState } = store;

  postApiCall(
    endPoints.riskmanagement.addSchoolManual,
    values,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        notify(s.data.message, 'success');
        cb({ ...values, _id: data._id });
        if (handleClose) {
          handleClose();
          reset();
        }
      } else if (statusCode && statusCode === apiErrorCode.validationError) {
        notify(s.data.message, 'error');
      }

      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};
export default riskMngslice.reducer;
